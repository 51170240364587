import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import {getSplitIOConfig} from "./app/js/configuration/environmentConfig";
import useRegion from "./app/js/customhooks/UseRegion";
import { SplitFactory } from "@splitsoftware/splitio-react";
import {AutoQuoteStoreProvider} from "./app/js/statemanagement/AutoQuoteDataStore";

(async () => {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    const MainComponent = lazy(() => import("./app/js/components/pages/MainComponent"));
    const region = useRegion();

    ReactDOM.render(
        <BrowserRouter basename={`/auto-insurance/${region}`}>
            <AutoQuoteStoreProvider>
                <SplitFactory config={getSplitIOConfig()}>
                    {isIE11 &&
                    <div id="root" style={{borderImage: "none", fontFamily: "Arial, Helvetica, sans-seriff", padding: "0 20px 30px 20px", width: "600px", margin: "0 auto"}}>
                        <p style={{borderImage: "none", textAlign: "center", fontFamily: "Arial, Helvetica, sans-seriff", marginTop: "40px", fontSize: "24px", marginBottom: "50px"}}>
                            <strong>We're sorry</strong><br/>Your browser is not supported
                        </p>
                        <p style={{textAlign: "center"}}>We recommend using the latest version of one of these browsers:</p>
                        <ul style={{marginLeft: "45px"}}>
                            <li>Chrome</li>
                            <li>Firefox</li>
                            <li>Microsoft Edge</li>
                            <li>Safari</li>
                        </ul>
                    </div>}
                    {!isIE11 &&<Suspense fallback={<div>Loading...</div>}><MainComponent /></Suspense>}
                </SplitFactory>
            </AutoQuoteStoreProvider>
        </BrowserRouter>
       ,
        document.getElementById('root')
    );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();