import {calculateAgeAsOfToday, differenceBetweenDatesInYears, addDaysToGivenDate, differenceBetweenDatesInDays} from "../common/DateUtils";
import { regions, reEffectiveByDate, rpc1510EffectiveByDate } from "../constant/constants";
import autoQuoteResources from "../../../resources/translations/en-us/autoquote";

const updateViewModel = (setViewModel, key, value) => {
    setViewModel(prevViewModel => ({
        ...prevViewModel,
        [key]: value
    }));
};

const updateNestedViewModel = (setViewModel, objectKey, key, value) => {
    setViewModel(prevViewModel => ({
        ...prevViewModel,
        [objectKey]: {...prevViewModel[objectKey], [key]: value}
    }));
};

const ageUnder15 = (value) => {
    const age = calculateAgeAsOfToday(value, 'MM-dd-yyyy');
    return (age >= 15)? null : "under15";
};

const ageOver100 = (value) => {
    const age = calculateAgeAsOfToday(value, 'MM-dd-yyyy');
    return (age < 100) ? null : 'over100';
};

const isFeb29DateValid = (value) => {
    let feb29Valid = true;
    if(value && value?.length === 10){
        const dateValues = value?.split("-")
        if(dateValues && dateValues[0] && dateValues[1] && dateValues[2] && dateValues[0] === '02' && dateValues[1] === '29'){
            feb29Valid = (Number.parseInt(dateValues[2]) % 4 === 0);
        }
    }
    return (feb29Valid)? null : "valid";
};

const emailsShouldMatch = (value1, value2) => {
    return (value1?.toLowerCase() === value2?.toLowerCase())? null : "match";
};

const customFieldValidationsPrimaryDriverBasicInfo = (viewModel) => {
    const customValidations = {"PrimaryDriverBasicInfo.emailConfirm" : {emailsShouldMatch: value => (emailsShouldMatch(value, viewModel.email))},
        "PrimaryDriverBasicInfo.DriverBasicInfo.dateOfBirth" : {feb29Validation: value => (isFeb29DateValid(value)), under15: value => (ageUnder15(value)), over100: value => (ageOver100(value))}};
    return customValidations
};

const customFieldValidationsPrimaryDriverAdditionalInfo = (viewModel) => {
    const customValidations = {"PrimaryDriverAdditionalInfo.DriverAdditionalInfo.licensedAge" : {under15: value => (value < 15)?"under15":null, valid: value => (value > calculateAgeAsOfToday(viewModel.basicInfo?.dateOfBirth, 'MM-dd-yyyy'))?"valid":null}, "PrimaryDriverAdditionalInfo.phoneNumber" : {extnWithoutPhoneNumber: value => (value === '' && viewModel.extension && viewModel.extension !== '')?"valid":null}};
    return customValidations
};

const customFieldValidationsAdditionalDriver = (viewModel) => {
    const customValidations = {"AdditionalDriver.DriverAdditionalInfo.licensedAge" : {under15: value => (value < 15)?"under15":null, valid: value => (value > calculateAgeAsOfToday(viewModel.basicInfo?.dateOfBirth, 'MM-dd-yyyy'))?"valid":null},
        "AdditionalDriver.DriverBasicInfo.dateOfBirth" : {feb29Validation: value => (isFeb29DateValid(value)), under15: value => (ageUnder15(value))}};
    return customValidations
};

const getPrimaryDriverAgeCategory = (dateOfBirth, region) => {
    const age = calculateAgeAsOfToday(dateOfBirth, 'MM-dd-yyyy');
    let ageCategory = '';
    if(age >= 69 && age < 101 && region === regions.OREGON) ageCategory ='Senior';
    else if(age >= 55 && ((age < 69 && region === regions.OREGON) || region === regions.CALIFORNIA)) ageCategory = 'Mature';
    else if(age >= 19 && age < 55) ageCategory = 'Adult';
    else if(age >= 15 && age < 19 && region === regions.OREGON) ageCategory = 'Minor';
    return ageCategory;
};

const getANICurrentValue = (driversList) => {
    let aniCurrentValue = '';
    Object.keys(driversList).forEach(driverKey => {
            if(driversList[driverKey].isAdditionalNamedInsured){
                aniCurrentValue = driversList[driverKey].driverId;
            }
        }
    );
    return aniCurrentValue;
};

const getCurrentAgeOfVehicle = (vehicleYear, effectiveDate, offset) => {
    var vehicleBirthDate = new Date(vehicleYear, 9, 1).toISOString();  
    if(effectiveDate === undefined){
        effectiveDate = addDaysToGivenDate(new Date(), offset).toISOString();
    }
    return differenceBetweenDatesInYears(effectiveDate, vehicleBirthDate);
};

const getRentalExpenseEffectiveDate = (effectiveDate, offset) => {
    const rentalExpenseEffectiveByDate = reEffectiveByDate.toISOString();
    if(effectiveDate === undefined){
        effectiveDate = addDaysToGivenDate(new Date(), offset).toISOString();
    }
    return differenceBetweenDatesInDays(effectiveDate, rentalExpenseEffectiveByDate);
};

const isRPC1510Effective = (effectiveDate) => {
    return differenceBetweenDatesInDays(effectiveDate.toISOString(), rpc1510EffectiveByDate.toISOString()) >= 0;
};

const isORZipCode = (value) => {
    const zipCode = value?.split('-')[0];
    return (zipCode > 97000 && zipCode < 97921)
};

const isCAZipCode = (value) => {
    const zipCode = value?.split('-')[0];
    return (zipCode > 90000 && zipCode < 96163)
};

const removeArrayValue = (arrayInput, value) => {
    var i = 0;
    while (i < arrayInput.length) {
        if (arrayInput[i] === value) {
            arrayInput.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arrayInput;
};

const getGAStepDataLayerDetails = (sectionName, stepName) => {
    const dataLayerDetails = {
        dataLayer: {
            event: 'stepLoad',
            funnelName: 'Auto Quote',
            sectionName: sectionName,
            stepName: stepName
        }
    };
    return dataLayerDetails;
};

const getProvinceCode = (region) => {
    if(region === regions.OREGON){
        return 'OR';
    }
    else if(region === regions.CALIFORNIA){
        return 'CA';
    }
    else return '';
};

const setYearUIValues = (yrs) => {
    if(yrs === 1) {
        return yrs + " year";
    }
    else if(yrs < 1){
        return "Less than 1 year";
    }
    else if(yrs >= 9){
        return "9 years or longer";
    }
    else{
        return yrs + " years";
    }
};

const isInsuranceCompanyNameMatch = (name) => {
    return autoQuoteResources.PrimaryDriverAdditionalInfo.currentInsuranceCompany.options.find(el => el.code === name);
};

export {updateViewModel, customFieldValidationsPrimaryDriverBasicInfo, customFieldValidationsPrimaryDriverAdditionalInfo, customFieldValidationsAdditionalDriver, getPrimaryDriverAgeCategory, getANICurrentValue, getCurrentAgeOfVehicle, isORZipCode, isCAZipCode, removeArrayValue, getGAStepDataLayerDetails, isFeb29DateValid, getProvinceCode, getRentalExpenseEffectiveDate, isRPC1510Effective, setYearUIValues, isInsuranceCompanyNameMatch, updateNestedViewModel}
