import React, { createContext } from "react";
import { useLocalStore } from "mobx-react-lite";
import PropTypes from "prop-types";
import {getInitialDataForPrimaryDriverBasicInfo, getInitialDataForPrimaryDriverAdditionalInfo, getInitialDataForAdditionalDriver, getInitialDataForVehicle} from "../utils/pageData/AutoQuotePageDataLoader";
import USVinService from "../services/vin/USVinService";
import {isEmpty} from "lodash";
import { getProvinceCode } from "../utils/page/PageUtils";
import {evaluateFlag, evaluateFlagTreatment, evaluateFlagConfig, isEnabled} from "../utils/featureflags/FeatureFlaggingUtils";

export const AutoQuoteStoreContext = createContext();
export const AutoQuoteStoreProvider = ({children}) => {
    const store = useLocalStore(() => ({

        primaryDriver: undefined,
        currentDriver: undefined,
        potentialDriver: undefined,
        skipDriverRecommendation:true,
        skipVehicleRecommendation:false,
        driversList: {},
        recommendedDriversList: {},
        selectedDriversList:{},
        currentVehicle: undefined,
        firstRecommendedVehicle: false,
        vehiclesList: {},
        recommendedVehiclesList: {},
        selectedVehiclesList: {},
        principalDriverAssignments: {},
        occasionalDriverAssignments: {},
        transactionData: {region : "US", quoteDeclined: false, quoteAssistance: false},
        vehicleMakeOptions: USVinService.getMakeOptions(),
        declineReasons: [],
        driverHasBeenSuspended: false,
        transactionInProgress: false, 
        region: "",
        mediaAlphaToken: undefined,
        offset: 1,
        featureFlags: [],

        saveRegion(region){
            store.region = region;
        },

        saveOffset(offset){
            store.offset = offset;
        },

        saveTransactionData(transactionData){
            store.transactionData = transactionData;
        },
        savePrimaryDriver(primaryDriver){
            if(primaryDriver){
                store.primaryDriver = primaryDriver;
                if(primaryDriver.driverId){
                    store.saveDriver(primaryDriver);
                }
            }
        },
        saveCurrentDriver(currentDriver){
            store.currentDriver = currentDriver;
        },
        saveDriver(driver){
            store.driversList[driver.driverId] = driver;
        },
        savePotentialDriver(driver){
            store.potentialDriver = driver;
        },
        saveRecommendedDriversList(recommendedDriversList){
            store.recommendedDriversList = recommendedDriversList;
        },
        saveSelectedDriversList(selectedDriversList){
            store.selectedDriversList = selectedDriversList;
        },
        saveDriversList(driversList){
            store.driversList = driversList;
        },
        saveCurrentVehicle(currentVehicle){
            store.currentVehicle = currentVehicle;
        },
        saveFirstRecommendedVehicle(firstRecommendedVehicle){
            store.firstRecommendedVehicle = firstRecommendedVehicle;
        },
        saveVehicle(vehicle){
            if(!store.vehiclesList){
                store.vehiclesList = {};
            }
            store.vehiclesList[vehicle.vehicleId] = vehicle;
        },
        saveRecommendedVehiclesList(recommendedVehiclesList){
          store.recommendedVehiclesList = recommendedVehiclesList;
        },
        saveSelectedVehiclesList(selectedVehiclesList){
          store.selectedVehiclesList = selectedVehiclesList;
        },
        saveVehiclesList(vehiclesList){
            store.vehiclesList = vehiclesList;
        },
        savePrincipalDriverAssignments(principalDriverAssignments){
            store.principalDriverAssignments = principalDriverAssignments;
        },
        saveOccasionalDriverAssignments(occasionalDriverAssignments){
            store.occasionalDriverAssignments = occasionalDriverAssignments;
        },
        saveQuoteDataModel(quoteDataModel){
            store.savePrimaryDriver(quoteDataModel.primaryDriver);
            store.saveTransactionData(quoteDataModel.transactionData);
            store.saveDriversList(quoteDataModel.driversList);
            store.saveVehiclesList(quoteDataModel.vehiclesList);
            store.savePrincipalDriverAssignments(quoteDataModel.principalDriverAssignments);
            store.saveOccasionalDriverAssignments(quoteDataModel.occasionalDriverAssignments);
        },
        saveDeclineReasons(declineReasons){
            store.declineReasons = declineReasons
        },
        saveDriverHasBeenSuspended(hasBeenSuspended) {
            store.driverHasBeenSuspended = hasBeenSuspended;
        },
        saveTransactionInProgress(transactionInProgress){
            store.transactionInProgress = transactionInProgress
        },
        saveMediaAlphaToken(mediaAlphaToken){
            store.mediaAlphaToken = mediaAlphaToken;
        },
        saveFeatureFlags(featureFlags){
            store.featureFlags = featureFlags;
        },
        resetStore(){
            store.primaryDriver=undefined;
            store.currentDriver= undefined;
            store.driversList= {};
            store.currentVehicle= undefined;
            store.vehiclesList= {};
            store.principalDriverAssignments= {};
            store.occasionalDriverAssignments= {};
            store.transactionData= {region : "US", quoteDeclined: false, quoteAssistance: false};
            store.vehicleMakeOptions= USVinService.getMakeOptions();
            store.declineReasons= [];
            store.transactionInProgress= false;
        },
        resetStoreExceptionTransactionData(){
            store.primaryDriver=undefined;
            store.currentDriver= undefined;
            store.driversList= {};
            store.currentVehicle= undefined;
            store.vehiclesList= {};
            store.principalDriverAssignments= {};
            store.occasionalDriverAssignments= {};
            store.vehicleMakeOptions= USVinService.getMakeOptions();
            store.declineReasons= [];
            store.transactionInProgress= false;
        },
        get primaryDriverDetails() {
            let primaryDriver = undefined;
            if(store.currentDriver){
                primaryDriver = store.currentDriver
            }
            else if(store.primaryDriver){
                if(store.primaryDriver?.driverId){
                    primaryDriver = store.primaryDriver
                }
                else{
                    primaryDriver = {...store.primaryDriver, ...getInitialDataForPrimaryDriverAdditionalInfo(store.region)}
                }
            }
            if(primaryDriver){
                return primaryDriver
            }
            return getInitialDataForPrimaryDriverBasicInfo(store.region);
        },
        get primaryDriverId() {
            return store.primaryDriver?.driverId;
        },
        get primaryDriverName() {
            return `${store.primaryDriver?.basicInfo.firstName} ${store.primaryDriver?.basicInfo.middleInitial} ${store.primaryDriver?.basicInfo.lastName}`;
        },
        get primaryDriverAddress() {
            return {...store.primaryDriver?.address, "unitNumberString": store.primaryDriver?.address.unitNumber? "UNIT "+store.primaryDriver?.address.unitNumber: ""};
        },
        get primaryDriverDateOfBirth() {
            return store.primaryDriver?.dateOfBirth;
        },
        get primaryDriverLicensedState() {
            return store.primaryDriver?.additionalInfo?.stateLicensed;
        },
        get additionalDriverDetails() {
            if(store.currentDriver?.driverId){
                return store.currentDriver
            }
            return getInitialDataForAdditionalDriver(store.region);
        },
        get vehicleDetails() {
            if(store.currentVehicle?.vehicleId){
                if(store.currentVehicle?.garagedAtPrimaryAddress){
                    store.currentVehicle = {...store.currentVehicle, ...{address: { streetAddress: '', unitNumber : '', poBox : '', city : '', province : getProvinceCode(store.region), zipCode : ''}}}
                }
                return store.currentVehicle
            }
            return {...store.currentVehicle, ...getInitialDataForVehicle(store.region)};
        },
        get numberOfDrivers() {
            return isEmpty(store.driversList)? 0 : Object.keys(store.driversList).length;
        },
        get numberOfRecommendedVehicles() {
            return isEmpty(store.recommendedVehiclesList)? 0 : Object.keys(store.recommendedVehiclesList).length;
        },
        get numberOfSelectedVehicles() {
            return isEmpty(store.selectedVehiclesList)? 0 : Object.keys(store.selectedVehiclesList).length;
        },
        get numberOfVehicles() {
            return isEmpty(store.vehiclesList)? 0 : Object.keys(store.vehiclesList).length;
        },
        get vehicleDisplayName() {
            return (vehicleId => store.vehiclesList[vehicleId].vehicleYear+' '+(store.vehicleMakeOptions.find(make => make.value === store.vehiclesList[vehicleId].vehicleMake)?.key)+' '+store.vehiclesList[vehicleId].vehicleModel);
        },
        isFeatureEnabled(featureName) {
            return isEnabled(store.featureFlags[featureName]);
        },
        evaluateFeatureIfEnabled(featureName) {
            return evaluateFlag(store.featureFlags[featureName]);
        },
        evaluateFeatureTreatmentIfEnabled(featureName) {
            return evaluateFlagTreatment(store.featureFlags[featureName]);
        },
        evaluateFeatureConfigIfEnabled(featureName) {
            return evaluateFlagConfig(store.featureFlags[featureName]);
        }
    }));

    AutoQuoteStoreProvider.propTypes = {
        children:PropTypes.any.isRequired,
    };

    return (
        <AutoQuoteStoreContext.Provider value={store}>{children}</AutoQuoteStoreContext.Provider>
    );
};